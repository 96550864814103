@tailwind base;
@tailwind components;

@font-face {
    font-family: "pixel";
    src: local("pixel_operator"),
    url("./resources/pixel_operator/PixelOperator.ttf") format("truetype");
}

body {
    @apply bg-black;
}

.glow {
    color: #fff;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
    from {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff, 0 0 50px #fff, 0 0 60px #fff, 0 0 70px #fff;
    }
    to {
        text-shadow: 0 0 20px #fff, 0 0 30px #6666ff, 0 0 40px #0099ff, 0 0 50px #ff3399, 0 0 60px #6666ff, 0 0 70px #ff4da6, 0 0 80px #fff;
    }
}

.glow-border {
    color: #fff;
    -webkit-animation: glow-border 1s ease-in-out infinite alternate;
    -moz-animation: glow-border 1s ease-in-out infinite alternate;
    animation: glow-border 1s ease-in-out infinite alternate;
    border: 1rem solid #fff;
    border-radius: 2rem;
    box-shadow: 0 0 .2rem #fff, 0 0 .2rem #fff, 0 0 2rem #bc13fe, 0 0 0.8rem #bc13fe, 0 0 2.8rem #bc13fe, inset 0 0 1.3rem #bc13fe;
}

@keyframes glow-border {
    from {
        box-shadow: 0 0 .2rem #fff, 0 0 .2rem #fff, 0 0 2rem #fff, 0 0 0.8rem #fff, 0 0 2.8rem #fff, inset 0 0 1.3rem #fff;
    }
    to {
        box-shadow: 0 0 .2rem #fff, 0 0 .2rem #6666ff, 0 0 2rem #0099ff, 0 0 0.8rem #ff3399, 0 0 2.8rem #6666ff, inset 0 0 1.3rem #ff4da6;
    }
}

.rainbow-text {
    text-decoration: underline;
    font-size: 32px;
    letter-spacing: 2px;
    animation: colorRotate 2s linear 0s infinite;
}

@keyframes colorRotate {
    from {
        color: #6666ff;
    }
    10% {
        color: #0099ff;
    }
    50% {
        color: #00ff00;
    }
    75% {
        color: #ff3399;
    }
    100% {
        color: #6666ff;
    }
}

.raffleThinger {
    color: white;
    font-size: x-large;
    text-align: center;
    border-left: 2px solid white;
    border-right: 2px solid white;
    border-radius: 12px;


    :nth-child(1) {
        opacity: .4;
        text-shadow: none;
        font-size: large;
    }

    :nth-child(2) {
        opacity: .8;
        text-shadow: none;
    }

    :nth-child(3) {
        font-weight: bold;
        font-size: xx-large;
    }

    :nth-child(4) {
        opacity: .8;
        text-shadow: none;
    }

    :nth-child(5) {
        opacity: .4;
        text-shadow: none;
        font-size: large;
    }
}

.label {
    padding: 20px;
}

.entryForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.entryInput {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.submit {
    margin-left: 100px;
}

.logo {
    padding: 10px;
}

@tailwind utilities;